import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaLinkedin, FaPinterest, FaStar } from 'react-icons/fa';

interface Company {
    guid: string;
    name: string;
    productName: string;
    url: string;
    rootUrl: string;
    summary: string;
    updatedAt: string;
    tags?: string[]; // Make tags optional
    links?: {
      aboutLinks?: string[];
      careersLinks?: string[];
      contactLinks?: string[];
      reviewLinks?: string[];
      socialLinks?: { site: string; url: string }[];
      imageLinks?: string[];
      email?: string[];
      allLinks?: string[];
    };
}

interface CompanyCardProps {
  company: Company;
  favorites: string[];
  toggleFavorite: (guid: string) => void;
  formatUrl: (url: string | undefined) => string;
  dedupeEmails: (emails: string[]) => string[];
  decodeEmail: (email: string) => string;
}

const CompanyCard: React.FC<CompanyCardProps> = ({ company, favorites, toggleFavorite, formatUrl, dedupeEmails, decodeEmail }) => {
  const [isImagesVisible, setIsImagesVisible] = useState<boolean>(false);

  // Determine if the current company is a favorite
  const isFavorited = favorites.includes(company.guid);

  // Function to return the appropriate social media icon based on the platform
  const getSocialIcon = (site: string) => {
    switch (site.toLowerCase()) {
      case 'facebook':
        return <FaFacebook />;
      case 'instagram':
        return <FaInstagram />;
      case 'youtube':
        return <FaYoutube />;
      case 'twitter':
        return <FaTwitter />;
      case 'linkedin':
        return <FaLinkedin />;
      case 'pinterest':
        return <FaPinterest />;
      default:
        return null;
    }
  };

  const uniqueSocialLinks = company.links?.socialLinks
    ? Array.from(new Set(company.links.socialLinks.map(link => JSON.stringify(link)))).map(link => JSON.parse(link))
    : [];

  return (
    <div className="company-card" key={company.guid}>
      {/* Product Name or Company Name as Link to URL */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        {company.productName ? (
          <h2>
            <a href={formatUrl(company.url)} target="_blank" rel="noopener noreferrer" className="product-link">
              {company.productName}
            </a>
          </h2>
        ) : (
          <h2>
            <a href={formatUrl(company.rootUrl)} target="_blank" rel="noopener noreferrer" className="product-link">
              {company.name}
            </a>
          </h2>
        )}

        {/* Favorite Star Icon */}
        <span onClick={() => toggleFavorite(company.guid)} style={{ cursor: 'pointer', color: isFavorited ? 'gold' : 'grey' }}>
          <FaStar />
        </span>
      </div>

      {/* Company Name as Link to Root URL in Smaller Font, only if there is a product name */}
      {company.productName && (
        <p className="company-name">
          <a href={formatUrl(company.rootUrl)} target="_blank" rel="noopener noreferrer" className="company-link">
            {company.name}
          </a>
        </p>
      )}

      <p>{company.summary}</p>

      {/* Collapsible Image Section */}
      {company.links?.imageLinks?.length ? (
        <>
          <button 
            className="toggle-images-button" 
            onClick={() => setIsImagesVisible(!isImagesVisible)}
          >
            {isImagesVisible ? 'Hide Images' : 'Show Images'}
          </button>
          {isImagesVisible && (
            <div className="image-grid">
              {company.links?.imageLinks?.map((imageLink, index) => (
                <img key={index} src={imageLink} alt={company.productName || company.name} />
              ))}
            </div>
          )}
        </>
      ) : null}

      {/* Social Links with Icons */}
      {uniqueSocialLinks.length > 0 && (
        <div className="social-links">
          {uniqueSocialLinks.map((social, index) => (
            <a 
              key={index} 
              href={formatUrl(social.url)} 
              target="_blank" 
              rel="noopener noreferrer"
              className="social-icon"
            >
              {getSocialIcon(social.site)}
            </a>
          ))}
        </div>
      )}

      {/* Links Section (About Us, Reviews, Contact Us, Careers) */}
      <p>
        {company.links?.aboutLinks?.length ? (
          <a href={formatUrl(company.links?.aboutLinks[0])} target="_blank" rel="noopener noreferrer">About Us</a>
        ) : null}

        {company.links?.reviewLinks?.length ? (
          <>
            {company.links?.aboutLinks?.length ? ' | ' : ''}
            <a href={formatUrl(company.links?.reviewLinks[0])} target="_blank" rel="noopener noreferrer">Reviews</a>
          </>
        ) : null}

        {company.links?.contactLinks?.length ? (
          <>
            {(company.links?.aboutLinks?.length || company.links?.reviewLinks?.length) ? ' | ' : ''}
            <a href={formatUrl(company.links?.contactLinks[0])} target="_blank" rel="noopener noreferrer">Contact Us</a>
          </>
        ) : null}

        {company.links?.careersLinks?.length ? (
          <>
            {(company.links?.aboutLinks?.length || company.links?.reviewLinks?.length || company.links?.contactLinks?.length) ? ' | ' : ''}
            <a href={formatUrl(company.links?.careersLinks[0])} target="_blank" rel="noopener noreferrer">Careers</a>
          </>
        ) : null}
      </p>

      {/* Updated On - Small and On One Line */}
      <p className="updated"><small>Updated On: {company.updatedAt}</small></p>
    </div>
  );
};

export default CompanyCard;
