import React, { useEffect, useState } from 'react';
import './ViewCompanies.css';
import CompanyCard from './CompanyCard';

interface Company {
  guid: string;
  name: string;
  productName: string;
  url: string;
  rootUrl: string;
  summary: string;
  updatedAt: string;
  tags?: string[]; // Make tags optional
  links?: {
    aboutLinks?: string[];
    careersLinks?: string[];
    contactLinks?: string[];
    reviewLinks?: string[];
    socialLinks?: { site: string; url: string }[];
    imageLinks?: string[];
    email?: string[];
    allLinks?: string[];
  };
}

const ViewCompanies: React.FC = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>(''); // Initial empty search term
  const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);
  const [favorites, setFavorites] = useState<string[]>(() => {
    const savedFavorites = JSON.parse(localStorage.getItem('favorites') || '[]');
    return Array.isArray(savedFavorites) ? savedFavorites : [];
  });
  const [showFavoritesOnly, setShowFavoritesOnly] = useState<boolean>(false);

  // Capture and set query parameter as the initial filter
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const query = queryParams.get('q'); // Extract the 'q' parameter

    if (query) {
      setSearchTerm(query); // Set the query parameter as the search term
    }
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await fetch('/gifts-2024-10-06a.json');
        const data: Company[] = await response.json();
        setCompanies(data);
        setFilteredCompanies(data);
      } catch (error) {
        console.error('Failed to fetch companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    localStorage.setItem('favorites', JSON.stringify(favorites));
  }, [favorites]);

  const toggleFavorite = (guid: string) => {
    setFavorites(prev => 
      prev.includes(guid) ? prev.filter(id => id !== guid) : [...prev, guid]
    );
  };

  const formatUrl = (url: string | undefined) => {
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url || '#';
  };

  const decodeEmail = (email: string): string => {
    try {
      const decodedEmail = decodeURIComponent(email);
      return decodedEmail.split('?')[0];
    } catch (e) {
      console.error('Error decoding email:', e);
      return email;
    }
  };

  const dedupeEmails = (emails: string[]): string[] => {
    const decodedEmails = emails.map(decodeEmail);
    return Array.from(new Set(decodedEmails));
  };

  // Apply filtering whenever the search term or companies change
  useEffect(() => {
    if (searchTerm.length >= 4) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      const filtered = companies.filter(company =>
        (company.name && company.name.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (company.productName && company.productName.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (company.summary && company.summary.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (company.tags && company.tags.some(tag => tag.toLowerCase().includes(lowerCaseSearchTerm))) // Check if tags exist before .some()
      );

      setFilteredCompanies(filtered);
    } else {
      setFilteredCompanies(companies);
    }
  }, [searchTerm, companies]);

  const displayedCompanies = showFavoritesOnly 
    ? filteredCompanies.filter(company => favorites.includes(company.guid)) 
    : filteredCompanies;

  const handleCommonSearch = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <div className="container">
      <h1>🔥🐯 TigerLlama.com 🦙🔥</h1>
      
      <div className="toggle-buttons">
        <button onClick={() => setShowFavoritesOnly(false)}>All</button>
        <button onClick={() => setShowFavoritesOnly(true)}>Favorites</button>
      </div>

      <input
        type="text"
        placeholder="Search by name, product, or category..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />

      <div className="filter-buttons">
        <button className="search-button" onClick={() => handleCommonSearch('')}>All</button>
        <button className="search-button" onClick={() => handleCommonSearch('gifts')}>Gifts</button>
        <button className="search-button" onClick={() => handleCommonSearch('home')}>Home</button>
      </div>

      <div className="company-grid">
        {displayedCompanies.map((company) => (
          <CompanyCard 
            key={company.guid}
            company={company}
            favorites={favorites}
            toggleFavorite={toggleFavorite}
            formatUrl={formatUrl}
            dedupeEmails={dedupeEmails}
            decodeEmail={decodeEmail}
          />
        ))}
      </div>

      {/* Footer with JanetBetter and BlameReid Links */}
      <footer className="footer-links">
        <p>
          <a href="https://janetbetter.com" target="_blank" rel="noopener noreferrer">
            JanetBetter.com - All About Home Improvement
          </a>
        </p>
        <p>
          <a href="https://reidcarlberg.com" target="_blank" rel="noopener noreferrer">
            BlameReid - ReidCarlberg.com
          </a>
        </p>
      </footer>
    </div>
  );
};

export default ViewCompanies;
