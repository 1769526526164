import React from 'react';
import './App.css'; // Assuming you have some global styles if needed
import ViewCompanies from './ViewCompanies'; // Import the component

const App: React.FC = () => {
  return (
    <div className="App">
      {/* This renders the ViewCompanies component */}
      <ViewCompanies />
    </div>
  );
}

export default App;
